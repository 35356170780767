import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: '/static/images/logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#Banner0_2',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>首页</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lxcysk7jboi-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#Content0_0',
          children: [
            {
              children: (
                <span>
                  <p>数智文旅</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Feature5_0',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>数智出版</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#Feature3_0',
          children: [
            {
              children: (
                <span>
                  <p>数智新基建</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: '#Banner4_0',
          children: [
            {
              children: (
                <span>
                  <p>AI数智能力底座</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner02DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: `${window.location.origin}/static/images/banner-logo.png`,
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <p>行业数智化解决方案提供商</p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button lxefhvcanka-editor_css',
    children: 'Learn More',
    href: '#Banner3_0',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>数智文旅</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${window.location.origin}/static/images/wl-col-pic-1.png`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>整合现有数据及资源</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>接入文旅数智能力平台的能力资源池中，形成特色能力资源库</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${window.location.origin}/static/images/wl-col-pic-2.png`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>掌握文旅全数据</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    纵向连接各企事业单位的基础及运行数据，横向整合公安、交通、气象等涉文旅数据
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${window.location.origin}/static/images/wl-col-pic-3.png`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>提升数字化能力复用</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>避免各部门及子公司信息化系统的重复研发与建设</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~lxeb155gkyb',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${window.location.origin}/static/images/wl-col-pic-4.png`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>市场引导数字化建设</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    支持各领域的数字化产品构建，降低构建难度，迅速适配市场需求，孵化轻应用
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~lxeb16kdseb',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                `${window.location.origin}/static/images/wl-col-pic-5.png`,
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>构建文旅大平台</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      通过“数智能力平台”构建文旅大平台，构建“大平台+轻应用+(能力池+数据池)”的整体架构
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>文旅应用孵化</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 3,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>智慧票务</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>一站式消费</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealihgfw',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>智慧攻略</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealp9p9nq',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>分时预约</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealqcfkwq',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>智慧餐饮</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealr0ld26',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>智慧民宿</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealrq56n7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>智慧停车</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealsdjizr',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>智能导游</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxealt4vmq',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>线路规划</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxean965i1l',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>旅游投诉</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeana7pgn',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxeaupnf93t-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>AI数字人</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanasl8qi',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxeat6yvpv7-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>AI旅游向导</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanbbxkpg',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxeax28cc6j-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>文旅短视频生产</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanbv1pg',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>AR寻宝</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeancemm1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>AR红包雨</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanczxlqs',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>AR剧本杀</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeandilv6m',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>徽章打卡</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanee58jk',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>聚合支付</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanewfxnu',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>游客画像</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanfdz73',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>旅游手帐</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanfun15s',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxeatq4gfu6-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>指挥调度</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeangfyk8d',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxeatm5y3ji-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>文创开发运营</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeanhnpbdf',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxearvtdi3h-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>线下创意活动</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 3,
        xs: 24,
        name: 'block~lxeaniad4wk',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group lxeavresumo-editor_css',
          children: [
            
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>济南文旅元宇宙</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>数智出版</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              搭平台、聚流量、做服务，通过打造线上线下、社内社外相融合的这一服务新场景，建立“文化生活圈+会员+知识服务+品质生活服务”的商业模式，实现杂志社期刊的数字化转型及杂志社向文化品质生活服务商的转变。
            </p>
          </span>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>社交互动</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'message' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <h3>通过搭建线上“文化生活圈”建立私域流量</h3>
                          <br />
                          <p>
                            围绕兴趣爱好、品质生活等各细分领域，比如摄影、旅行、合唱、健康……等，在平台上搭建不同的社交圈。可在平台上建立自己的账号，并加入自己喜欢的圈子，实现社交互动、作品分享、情感归属、价值实现等需求。<span
                            >
                              平台以此实现用户的快速聚集、黏性凝聚及用户消费需求的细分。
                            </span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              '/static/images/col-pic.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'layout' },
          text: {
            className: 'content7-tag-name',
            children: (
              <span>
                <p>
                  <span>服务</span>
                  <br />
                </p>
              </span>
            ),
          },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <h3>知识服务</h3>
                      <br />
                      <p>
                        重塑产品形态，主要通过平台提供三种类型的知识产品：数字刊、音视频课程、有声书。
                      </p>
                      <br />
                      <br />
                      <h3>品质生活服务</h3>
                      <br />
                      <p>
                        围绕特性需求，不断拓展产业链。通过平台主要提供旅游、用品商城和大赛活动三个方面的服务。
                      </p>
                      <br />
                      <h3>
                        <br />
                      </h3>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              '/static/images/col-pic.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>会员系统</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'team' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <h3>定制化推送及管理</h3>
                            <br/>
                            <p>
                              <span>
                                会员系统即用户数据库系统，是平台的支持系统，在用户注册及用户在线活动时，完成对用户的信息采集，可实现用户的画像、大数据分析，用于内容和服务的个性化推送及会员管理。
                              </span>
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              '/static/images/col-pic.png',
          },
        },
      },
    ],
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>数智新基建</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>数智化赋能新基建 为企业带来产业升级新“基”遇</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/images/jj-col-pic-1.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>投资管理数智化</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>
                    切实结合国资平台企业投资业务的特点与需求，实现投资“募-投-管-退”全生命管理数智化；对投资过程中的现存风险和潜在风险进行智能检测及上报，增强风险防范能力，促进企业健康发展。
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/images/jj-col-pic-2.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>融资债务数智化</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  实现从融资需求计划到债务偿还全生命周期管理的数智化；摸清家底，精准测算未来债务，智能债务预警，有效防范债务逾期风险，保障企业征信。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/images/jj-col-pic-3.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>工程项目数智化</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  实现工程项目立项-前期管理-建设管理-竣工验收全生命周期管理数智化；实现整个项目规范化、流程化、精细化的全方位管理，降低项目成本与风险，提高集团规范和效率。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/images/jj-col-pic-4.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>资产经营数智化</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  为实现集团资产取得、使用、经营和处置全生命周期数智化管控；摸清家底，盘活资产，实现“经营有方法、创收无止境”的管理模式，最大化实现资产保值增值。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/images/jj-col-pic-5.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>企业运营数智化</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  实现财务、人资、办公、资金、党建、三重一大、风控等业务的集团化运营管控；提升企业运营管理效率，对系统产生的核心业务数据进行多维分析和预警，应用更智慧，管理更高效。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/static/images/jj-col-pic-6.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>大数据融合分析</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  实现
                  “多端合一”大数据融合分析；将企业的核心业务数据进行可视化建模分析，通过门户进行多维度呈现和自动预警。
                </p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4' },
  page: { className: 'home-page banner4-page' },
  childWrapper: {
    className: 'banner4-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>AI数智能力底座</p>
              </span>
            </span>
          </span>
        ),
        className: 'banner4-title',
      },
      {
        name: 'content',
        className: 'banner4-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      <span>
                        “数智能力底座平台”采用“平台+应用”的设计理念，将支撑业务运营和日常工作的各类应用系统，构建在一个具有高性能、高扩展、高安全、易管理的基础平台上。这个系统以“云”的概念，为整个企业提供各类业务能力，并通过这个平台实现SOA的业务目标。
                      </span>
                      <br />
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        children: { href: '#', type: 'primary', children: '开始使用' },
      },
    ],
  },
  image: {
    className: 'banner4-image',
    children:
      '/static/images/sz-pic.png',
  },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  textWrapper: {
    className: 'banner3-text-wrapper',
    children: [
      {
        name: 'nameEn',
        className: 'banner3-name-en',
        children: (
          <span>
            <p>Shandong Future Digital Technology Co., Ltd</p>
          </span>
        ),
      },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        children: '山东未来数字技术有限公司',
        texty: true,
      },
      {
        name: 'name',
        className: 'banner3-name lxe1xz867c7-editor_css',
        children: (
          <span>
            <p>
              山东未来数字技术有限公司是贯彻落实山东省委省政府实施文化强省战略，根据《山东出版集团与山东未来网络研究院签署的战略合作协议》，依托山东未来网络研究院成立的专业从事数字技术与出版、教育、旅游等文化产业融合创新、实施数字文化领域科技成果转移转化的实体单位。公司拥有大数据、区块链、人工智能、数字孪生、元宇宙等核心技术团队，具备基于用户需求进行业务平台及应用的定制开发能力，具体承担山东省数字与数据融合出版传媒重点实验室的技术研发及成果转化工作，是山东省数字融合出版创新创业共同体成员单位之一。
            </p>
          </span>
        ),
      },
      {
        name: 'time',
        className: 'banner3-time',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <a href='https://beian.miit.gov.cn/' target='_blank' rel="noopener noreferrer" style={{color:'#999'}}>鲁ICP备2023029436号-1</a>&nbsp;&nbsp;©2024&nbsp;山东未来数字技术有限公司&nbsp;All Rights Reserved
      </span>
    ),
  },
};
